<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title><v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-8 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
<div class="mt-6 cont" :style="{height:'100%', width:'100%'}">
  <div>
    <v-chip outlined :style="{fontSize:'16px',marginTop:'1em',marginBottom:'4px'}"><fai icon=""/>&nbsp;Order Information</v-chip>

    <div>
                <v-progress-linear indeterminate v-show="order_progress"></v-progress-linear>
                <div :style="{display:'flex',flexDirection:'column',flexWrap:'wrap'}">

                <div>
                <v-chip flat class="mb-1">Product Purchased</v-chip>
                <div class="d-flex mt-1" v-for="(item,index) in order_info_product" :key="index">
                  <v-card height="150px" width="150px" tile flat class="pa-3 mx-3">
                    <v-img class="img mx-1" :src="$store.getters.get_api+'fetchProductPic/'+item.image"></v-img>
                  </v-card>
                  <div  class="caption" :style="{display:'flex',flexDirection:'column',flexWrap:'wrap'}">
                    <span class="subtitle" maxWidth="50px" :style="{overflowX:'ellipsis'}"><strong>{{item.product_name}}</strong></span>
                    <span class="subtitle"><strong :style="{color:'red'}">Qty: </strong>&nbsp;<strong>{{item.quantity}}</strong></span>
                    <span>Market Price: {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.price)}}</span>

                    <span>Purchased AT: {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(item.purchase_price)}}</span>

                    <span>Discount <strong :style="{color:'green'}">{{item.discount}}%</strong></span>

                  </div>
                </div>
              </div>

              <div class="cinfo">
                <div class="" :style="{fontSize:'15px'}">
                  <v-chip outlined class="mb-1 mt-1">Contact Information</v-chip>
                  <div><strong>Shipping Fee:</strong> {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(shipping_fee)}}</div>

                  <div><strong>Service Fee:</strong> {{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(service_charge)}}</div>
                  <div><strong>Firstname:</strong> {{order_info_contact.firstname}}</div>
                  <div><strong>Lastname:</strong> {{order_info_contact.lastname}}</div>
                  <div><strong>Mobile:</strong> {{order_info_contact.phonenumber}}</div>
                  <div><strong>Address:</strong> {{order_info_contact.address}}</div>
                  <div><strong>Landmark:</strong> {{order_info_contact.landmark}}</div>
                  <div><strong>City:</strong> {{order_info_contact.city}}</div>
                  <div><strong>LGA:</strong> {{order_info_contact.LGA}}</div>
              </div>

              <div id="shipping">
                <!--SHIPPING COMPANY INFORMATION-->
                <v-card width="250" :style="{padding:'5px', display:'flex',flexDirection:'column'}">
                  <v-card-text class="caption">Shiping Company</v-card-text>
                  <div class="d-flex justify-center"><v-avatar size="70">
                   <v-img v-if="shipping_company.logo" :src="$store.getters.get_api+'fetchCompanyPic/'+shipping_company.logo"></v-img>
                  </v-avatar></div>
              <v-card-text><strong>{{shipping_company.name}}</strong></v-card-text>
              <v-card-text><strong>{{shipping_company.call_center}}</strong></v-card-text>
              </v-card>
              </div>
              </div>

            </div>
            </div>

  </div>

  <div class="colx pa-4" v-if="payment_box===0">
    <v-alert type="info" v-if="payment_box===0">Order has not been Completed</v-alert>

    <!--FOR SPECIAL SELLERS ONLY-->
  <div v-if="special_seller===1">
    <v-alert type="error">SPECIAL SELLER</v-alert>
    <span v-if="!order_info_product[0].seller_paid" :style="{fontFamily:'Roboto'}">
      Click the button below to process special seller payment.
    </span><br/>
    <v-btn @click="openSellerPaymentOptions" v-if="!order_info_product[0].seller_paid" rounded color="primary">Pay Seller</v-btn>
    <v-chip v-if="order_info_product[0].seller_paid" color="green" dark>SELLER PAID</v-chip>
    <v-progress-circular class="ml-2" indeterminate v-show="special_indicator"></v-progress-circular>
  </div>
  <!--for speciaal sellrs only-->

  </div>


  <div class="colx pa-4" v-if="payment_box!==0">
    <v-chip outlined><fai icon=""/>&nbsp;Process Payment</v-chip>
    <v-btn class="mx-2" small rounded color="primary" dark>Order Summary</v-btn>

    <div class="mb-3 bx pa-6" v-for="(i , index) in order_info_product" :key="index" width="100%">
      <div><strong>Seller's Shop:</strong>&nbsp;{{i.seller_shop}}</div>
      <div class="caption">
        Indicates that this product was collected from the seller by the delivery company.
      </div>
      <div :style="{display:'flex',justifyContent:'space-between'}">
          <strong>Product Collected&nbsp;&nbsp;</strong>
          <fai v-if="payment_box[index].product_received===true" color="green" icon="check-square"/>
          <fai v-if="payment_box[index].product_received===false" color="red" icon="times-circle"/>
      </div>

      <div class="caption">
        Indicates that this product was delivered by the shipping company to the buyer.
      </div>
      <div :style="{display:'flex',justifyContent:'space-between'}">
          <strong>Product Delivered&nbsp;&nbsp;</strong>
          <fai v-if="payment_box[index].product_delivered===true" color="green" icon="check-square"/>
          <fai v-if="payment_box[index].product_delivered===false" color="red" icon="times-circle"/>
      </div>
      <div class="caption">
        <div><strong>Quantity Collected:&nbsp;{{payment_box[index].quantity_received}}</strong></div>
        <div><strong>Quantity Delivered:&nbsp;{{payment_box[index].quantity_delivered}}</strong></div>
        <div><strong>Quantity Ordered for:&nbsp;{{payment_box[index].quantity_ordered}}</strong></div>
    
        <div :style="{display:'flex',justifyContent:'space-between'}">
          <span><strong>Seller Settlement:</strong>&nbsp;{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(payment_box[index].amount_due_for_seller)}}</span>

          <span><strong>Buyer Refund:</strong>&nbsp;{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(payment_box[index].amount_due_for_buyer)}}</span>
        </div>
        <v-chip dark color="red" v-if="parseInt(payment_box[index].quantity_received)>parseInt(payment_box[index].quantity_delivered)">Shipping Company will account for&nbsp;<strong>{{payment_box[index].quantity_received-payment_box[index].quantity_delivered}}</strong></v-chip>
        <div class="mt-1">
          <v-chip outlined color="green" v-if="i.seller_paid===1"><strong>Seller Paid</strong></v-chip>
        <v-chip outlined color="red" v-if="i.seller_paid===0"><strong>Seller Not Paid</strong></v-chip>

        <v-chip outlined color="blue" v-if="i.seller_paid===2"><strong>Buyer Refunded</strong></v-chip>
        </div>

        <div class="mt-2">
        <v-btn color="color1" dark @click.stop="openConfirm({'seller':i.seller_id,'amount':parseFloat(payment_box[index].amount_due_for_seller),'index':index},'SETTLEMENT')" rounded class="mx-2" v-if="i.seller_paid===0">Pay Seller</v-btn>
        
        <v-btn v-if="parseInt(payment_box[index].quantity_received)>parseInt(payment_box[index].quantity_delivered)&&(i.seller_paid===0)" color="color2" dark @click.stop="openConfirm({'buyer':i.buyer_id,'amount':payment_box[index].amount_due_for_buyer*(payment_box[index].quantity_received-payment_box[index].quantity_delivered),'index':index},'REFUND')" rounded>Refund Buyer</v-btn>
        <!--<v-btn color="color2" dark @click.stop="openConfirm({'buyer':i.buyer_id,'amount':parseFloat(payment_box[index].amount_due_for_buyer*(parseInt(payment_box[index].quantity_received)-parseInt(payment_box[index].quantity_ordered))),'index':index},'REFUND')" rounded v-if="i.seller_paid===0">Refund Buyer</v-btn>-->

      </div>
      </div>
    </div>
    <!--
    <div class="mb-3 bx pa-6" v-for="(i , ind) in order_info_product" :key="ind" width="100%" flat tile>
      <div><strong>Sellers Shop:</strong>&nbsp;{{i.seller_shop}}</div>
      <div><strong>Amount Due:</strong>&nbsp;{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(i.price-(i.discount/100)*i.price)}}</div>
      <div>
        <strong>Payment Status</strong>&nbsp;
        <v-chip outlined color="green" v-if="i.seller_paid===1"><strong>Seller Paid</strong></v-chip>
        <v-chip outlined color="red" v-if="i.seller_paid===0"><strong>Not Paid</strong></v-chip>

        <v-chip outlined color="blue" v-if="i.seller_paid===2"><strong>Buyer Refunded</strong></v-chip>
      </div>
      <div class="mt-2">
        <v-btn @click.stop="openConfirm({'seller':i.seller_id,'amount':i.price-(i.discount/100)*i.price,'index':ind},'SETTLEMENT')" rounded class="mx-2" v-if="i.seller_paid===0">Pay Seller</v-btn>
        <v-btn @click.stop="openConfirm({'buyer':i.buyer_id,'amount':i.price-(i.discount/100)*i.price,'index':ind},'REFUND')" rounded v-if="i.seller_paid===0">Refund Buyer</v-btn>

      </div>
    </div>
    --->
    <div>
    <!--  <v-btn small @click.stop="payAllSellers" color="primary" v-if="order_status===3" rounded class="mx-2">Pay All Sellers</v-btn>-->
      <v-btn small color="primary" v-if="order_status===3" rounded @click.stop="openConfirm(order_id,'COMPLETE')">Complete Order</v-btn>
    </div>
  </div>

</div>
   

        <v-dialog persistent v-model="confirmation" maxWidth="350">
        <v-card>
          <v-img :src="img"/>
          <v-card-title :style="{fontSize:'16px'}">{{c_message}}</v-card-title>
          <v-card-actions class="d-flex justify-center">
            <div :style="{display:'flex',justifyContent:'space-between',padding:'2px'}">
            <v-btn @click="cancelConf" class="mx-2" large rounded>No</v-btn>
            <v-btn @click="proceed" class="mx-2" large rounded>Yes</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog> 


      <v-dialog persistent v-model="showPaymentOptions" maxWidth="350">
        <v-card>
          <v-card-title :style="{fontSize:'16px',fontWeight:'bold'}">Seller Payment Options</v-card-title>
          <div class="payment-container">
			
            <div class="optionp" v-for="(options,index) in paymentOptions" :key="index">
			<input type="radio" :value="options.id" v-model="selectedPaymentOption"/>
                <div>Bank Name&nbsp;{{options.bank_name}}</div>
                <div>Account Name&nbsp;{{options.account_name}}</div>
                <div>Account Number&nbsp;{{options.account_number}}</div>
            </div>
     
          </div>
          <v-card-actions class="d-flex justify-center">
<v-btn @click="showPaymentOptions=false" color="red" class="primary">Cancel</v-btn>
<v-btn @click="specialSellerPayment" class="primary">Pay Seller</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
  <!---END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>


      <v-overlay v-show="overlay">
           <v-progress-circular v-show="progresso" indeterminate :color="fill"></v-progress-circular>
           <v-alert :type="alert_type" shaped v-show="alert_box">
               {{alert_message}}
               <template v-slot:close><v-btn icon @click.stop="closeOverlay"><fai icon="close"/></v-btn></template>
               </v-alert>
        </v-overlay>

  </body>
  
</template>

<script>
export default {
    data:()=>{
        return{
            special_indicator:false,
            //overlay:false,
            fill:'blue',
            progresso:false,
            alert_type:undefined,//success,info,warning
            alert_box:false,
            alert_message:'',

            order_progress:false,
            confirmation:false,
            c_message:'',
            progress:false,
            option:'',
            c_data:'',
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            order_status:undefined,
            img:require('../assets/undraw_questions_re_1fy7.svg'),
            sideMenu:[
                
            ],
            order_id:undefined,
            order_info_product:[],
            order_info_contact:{},
            shipping_company:{},
            payment_box:[],
            service_charge:0,
            shipping_fee:0,

            seller_to_pay:undefined,
            buyer_to_refund:undefined,
            order_to_complete:undefined,
            confirm_for:'',//REFUND,SETTLEMENT,COMPLETE
            special_seller:undefined,
            /**
             * TEST DATA
             */
             paymentOptions:[],
             showPaymentOptions:false,
             selectedPaymentOption:undefined
            
        }
    },
    components:{
  
    },
    methods:{
      specialSellerPaid(){
        this.special_indicator=true;
      },
      payAllSellers(){
      this.overlay=true;
      this.progresso=true;
      this.axios({
        url:this.$store.getters.get_api+'payAllSellers/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        if(!data.error){
           this.alert_box=true;
           this.alert_type='success'
           this.alert_message='All Sellers credited successfuly'
           
           for( let i=0; i<this.order_info_product.length; i++){
              this.order_info_product[i].seller_paid=1;
           }
        }
        this.progresso=false;
      }).catch((error)=>{
        return error;
      })
      },
      proceed(){
        this.c_message='';
        this.confirmation=false;
        if(this.confirm_for==='REFUND'){
          //this.buyer_to_refund=data;
          this.refundBuyer();
        }
        if(this.confirm_for==='SETTLEMENT'){
          //this.seller_to_pay=data;
          this.creditSeller();

        }
        if(this.confirm_for==='COMPLETE'){
          //this.order_to_complete=data;
         // console.log('completed')
          this.completeOrder();
        }
      },
      closeOverlay(){
        this.overlay=false;
        this.alert_type=undefined;
        this.alert_message='';
        this.alert_box=false;
      },
      refundBuyer(){
       // console.log('refunding buyer')
        this.overlay=true;
        this.progresso=true;
        const form = new FormData()
        form.append('order_id',this.order_id)
        form.append('buyer' , this.buyer_to_refund.buyer)
        form.append('amount', this.buyer_to_refund.amount)
        form.append('index' , this.buyer_to_refund.index)
        this.axios({
        url:this.$store.getters.get_api+'refundBuyer',
        method:'post',
        data:form,
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
       // console.log(data)
        if(!data.error){
           this.alert_box=true;
           this.alert_type='success'
           this.alert_message='Buyer refunded successfuly'
           this.order_info_product[this.buyer_to_refund.index].seller_paid=2;
        }
        this.progresso=false;
      }).catch((error)=>{
        return error;
      })
      },
      openSellerPaymentOptions(){
        this.$store.dispatch('openProgress');
        this.axios({
          url:this.$store.getters.get_api+'fetchUserPaymentMethodAdmin/'+this.order_id,
          method:'get',
          headers:{
                  "Access-Control-Allow-Origin":"*",
                  'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
        }).then((response)=>{
          if(!response.data.error){
             this.$store.dispatch('closeProgress');
             this.showPaymentOptions=true;
              this.paymentOptions=response.data.message;
          }else{
            this.$store.commit('set_progress',false);
            this.$store.commit('set_error',true);
            this.$store.commit('set_message',response.data.message);
            //throw error;
          }
        }).catch((error)=>{
            this.$store.commit('set_progress',false);
            this.$store.commit('set_error',true);
            this.$store.commit('set_message','An error occured while fetching payment methods');
            throw error;
        })
      },
      specialSellerPayment(){
		this.showPaymentOptions=false;
        this.$store.dispatch('openProgress')
        this.axios({
          url:this.$store.getters.get_api+'paySpecialSeller/'+this.order_id+'/'+this.selectedPaymentOption,
          method:'get',
          headers:{
                  "Access-Control-Allow-Origin":"*",
                 'Authorization':`Bearer ${this.$store.getters.get_token}`
         }
        }).then((res)=>{
          if(!res.data.error){
            this.$store.dispatch('closeProgress');
            this.seller_paid=1;
          }
          else{
            this.$store.commit('set_progress',false)
          this.$store.commit('set_error',true)
          this.$store.commit('set_message',res.data.message)
          }
        }).catch((error)=>{
          this.$store.commit('set_progress',false)
          this.$store.commit('set_error',true)
          this.$store.commit('set_message','Network Error')
          throw error;
        });
      },
      creditSeller(){
        this.overlay=true;
        this.progresso=true;
        const form = new FormData()
        form.append('order_id',this.order_id)
        form.append('seller' , this.seller_to_pay.seller)
        form.append('amount', this.seller_to_pay.amount)
        form.append('index' , this.seller_to_pay.index)
      // console.log(this.seller_to_pay.amount)
        this.axios({
        url:this.$store.getters.get_api+'paySeller',
        method:'post',
        data:form,
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        //console.log(data)
        if(!data.error){
           this.alert_box=true;
           this.alert_type='success'
           this.alert_message='Seller credited successfuly'
           this.order_info_product[this.seller_to_pay.index].seller_paid=1;
        }
        this.progresso=false;
      }).catch((error)=>{
        return error;
      })
      },
      creditAllSellers(){
        this.progresso=true;
      },

      completeOrder(){
        console.log('completing project')
      this.progresso=true;
      this.axios({
        url:this.$store.getters.get_api+'completeOrder/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        if(!data.error){
          this.order_status=4;
           this.alert_box=true;
           this.alert_type='success'
           this.alert_message='Seller credited successfuly'
           this.order_info_product[this.seller_to_pay.index].seller_paid=1;
        }
        this.progresso=false;
      }).catch((error)=>{
        return error;
      })
    },

      openConfirm(data,type){
       // console.log(data)
        if(type==='REFUND'){
          this.buyer_to_refund=data;
          this.confirm_for='REFUND';
          this.c_message='You are about to refund the buyer '+new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(this.buyer_to_refund.amount)+'proceed to complete the refund?';
        }
        if(type==='SETTLEMENT'){
          this.seller_to_pay=data;
          this.confirm_for='SETTLEMENT';
          this.c_message='You are about to credit the seller '+new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(this.seller_to_pay.amount)+',proceed with this action';
        }
        if(type==='COMPLETE'){
          this.order_to_complete=data;
          this.confirm_for='COMPLETE';
          this.c_message='Do you want to complete this order?';
        }
        this.confirmation=true;

      },
      cancelConf(){
        this.confirmation=false;
      },
      fetchOrderInfo(order_id){
      this.order_id=order_id
      this.order_progress=true;
      this.order_info_a=true;
      this.axios({
        url:this.$store.getters.get_api+'fetchOrderDataExtra/'+this.order_id,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
       // console.log(data)
        if(!data.error){
           this.order_info_product = data.products;
           this.order_info_contact = data.contact_info;
           this.shipping_company = data.shipping_company;
           this.service_charge = data.service_charge;
           this.shipping_fee = data.shipping_fee;
           this.order_status = data.order_status;
           this.payment_box = data.payment_box;
           this.special_seller = data.products[0].special_seller_status;
         //  console.log(this.payment_box)
        }
        this.order_progress=false;
      }).catch((error)=>{
        return error;
      })
    },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
    
    },
    created(){

    },
    mounted(){
     this.order_id = this.$route.params.order_id;
      //this.getAllUsers(1)
      this.sideMenu=this.$store.getters.get_menu;
       this.fetchOrderInfo(this.order_id)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
    }
}
</script>
<style scoped>
@font-face {
font-family: "raleway";
src: url(../assets/Raleway-Bold.ttf) format("truetype"),
/*url("CustomFont.otf") format("opentype"),
url("CustomFont.svg#filename") format("svg");*/
}
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  background:#fff;
}
.icon-1{
    font-size: 50px;
}
@media screen and (min-width: 450px){
  .cinfo{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  #shipping{
    margin-left:3em;
  }
.colx{
  margin-top: 2px;
  margin-left:16%;
  min-width: 500px;
}
.alert{
  width:500px;
}
.cont{
  display:flex;
  flex-flow: row nowrap;
}
}
.bx{
  background: #fff;
  width:100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 10px;
  font-family: sans-serif;
  box-shadow: 2px 6px 4px rgba(255,255,255,.2),
              -2px -6px 4px rgba(255,255,255,.2);
}
.cinfo{
  margin-top:8px;
  padding:6px;
  border-radius: 20px;
  font-family:Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  background: #fff;
  box-shadow: 2px 6px 4px rgba(255,255,255,.2),
              -2px -6px 4px rgba(255,255,255,.2);

}
.optionp{
	padding:2px 1rem;
	font-family:Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
	border-bottom: solid 1px #fe7b00;
}
</style>